import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { useIntl } from '../../util/reactIntl';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';

import { H3, Page, LayoutSingleColumn } from '../../components';
import css from './PrivacyPolicyPage.module.css';

// This "content-only" component can be used in modals etc.
const PrivacyPolicyContent = props => {

  return (
    <div className={css.container}>
      <H3 as="h2" className={css.title}>
        Datenschutzerklärung Dealgo.ch
      </H3>
      <p>Diese Datenschutzerklärung informiert Sie darüber, wie dealgo.ch bei der Nutzung unserer Webseiten, Plattformen, Portale oder mobilen Applikationen (nachfolgend gemeinsam „Angebot„) sowie im Rahmen einer Zusammenarbeit mit Ihren personenbezogenen Daten umgehen, an wenn wir Ihre Daten gegebenenfalls weitergeben können und welche Rechte Ihnen im Hinblick auf die Verwendung Ihrer Daten gegenüber uns zustehen.</p>
      <p>Wir können Ihnen zusätzliche Datenschutzerklärungen zur Verfügung stellen, wenn wir dies für sinnvoll halten. Solche zusätzlichen Datenschutzerklärungen ergänzen diese Datenschutzerklärung und müssen mit dieser zusammen gelesen werden.</p>
      <p>Wir bearbeiten Ihre personenbezogenen Daten stets im Einklang mit den geltenden datenschutzrechtlichen Bestimmungen (inklusive dem Schweizer Datenschutzgesetz, „DSG“ sowie, sofern anwendbar, die EU Datenschutz-Grundverordnung, „DSGVO„; nachfolgend gemeinsam „anwendbares Datenschutzrecht„).</p>
      <p>„Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche oder, sofern durch das DSG geschützt, juristische Person beziehen. Dies können zum Beispiel Ihr Vorname und Name, Postadresse, E-Mail-Adresse, Geburtsdatum, Telefonnummer sowie Daten über die Nutzung unserer Website, getätigte Käufe über unsere Portale sowie Ihre Vorlieben sein. In diesen Datenschutzbestimmungen wird hierfür auch die Formulierung „Ihre Daten“ verwendet. Informationen, die nicht direkt oder indirekt mit Ihrer Person, also nicht mit Ihrer Identität, in Verbindung gebracht werden können, sind grundsätzlich keine personenbezogenen Daten.</p>
      <p>Als „Bearbeitung“ gilt jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung Ihrer Daten.</p>
      <p>Bitte beachten Sie, dass Webseiten Dritter, die über unsere Angebote erreichbar sind, in ihren eigenen Datenschutzerklärungen über die Datenbearbeitung durch Sie informieren. Wir übernehmen keine Verantwortung oder Haftung für die Einhaltung des Datenschutzes durch diese Dritte und empfehlen Ihnen, die Datenschutzerklärungen dieser Webseiten Dritter zu konsultieren.</p>
      <ol className={css.mainList}>
        <li>
          <strong>Wer ist verantwortlich für die Bearbeitung Ihrer Daten?</strong>
          <p>
            Website dealgo.ch ist verantwortlich für die Bearbeitung Ihrer Daten, die im Rahmen unserer Angebote oder Zusammenarbeit mit Ihnen gesammelt oder erhalten oder für andere Zwecke, wie in dieser Datenschutzerklärung definiert, bearbeitet werden.
          </p>
        </li>
        <li>
          <strong>
            Wie erheben wir Ihre Daten?
          </strong>
          <p>
            Wir erheben Ihre Daten jeweils direkt bei Ihnen oder erhalten Ihre Daten von Dritten, wie z.B. mit uns verbunden Unternehmen, Behörden, Beratern, unseren Dienstleistern oder aus öffentlich zugänglichen Quellen.
          </p>
        </li>
        <li>
          <strong>
            Umfang, Zweck und Rechtsgrundlage der Bearbeitung Ihrer Daten
          </strong>
          <p>
            Wir bearbeiten Ihre Daten, wenn Sie unsere Angebote nutzen, mit uns zusammenarbeiten oder anderweitig mit uns in Kontakt treten.
          </p>
          <p>
            Grundsätzlich bearbeiten wir Ihre Daten zu folgenden Zwecken:
          </p>
          <ul style={{ listStyleType: "disc", paddingLeft: 40 }}>
            <li>
              zur Erfüllung und Abwicklung von vertraglichen oder gesetzlichen Verpflichtungen;
            </li>
            <li>
              zur Überprüfung der Zugangsberechtigung und Verwaltung des Benutzerkontos (falls vorhanden);
            </li>
            <li>
              zur Kommunikation mit Ihnen und zur Information über Aktualisierungen oder Änderungen unserer Angebote oder Veranstaltungen sowie bei Anpassungen unserer AGB,  Datenschutzerklärungen, unserer Vorgaben, wie Sie auf unseren Portalen und Webseiten inserieren dürfen;
            </li>
            <li>
              zur Betrugs- und Missbrauchsbekämpfung;
            </li>
            <li>
              zur Entwicklung und Pflege der Kundenbeziehung;
            </li>
            <li>
              für Marketingzwecke von uns sowie Dritten, d.h. alle verkaufsfördernden Massnahmen, z.B. Newsletter, Telefon, Online-Werbung, SMS und Push Notifications zur Marktforschung, Durchführung von Analysen und Umfragen sowie für statistische Zwecke;
            </li>
            <li>
              zur bedarfsgerechten Ausgestaltung und Verbesserung von Angeboten;
            </li>
            <li>
              zur Auswertung des Nutzerverhaltens;
            </li>
            <li>
              Anbahnung von Vertragsbeziehungen mit Dritten;
            </li>
            <li>
              zu Schulungszwecken.
            </li>
          </ul>
          <p>
            Nachfolgend haben wir für Sie im Detail zusammengefasst, welche Daten wir im Rahmen unserer Angebote bearbeiten, wozu wir diese bearbeiten und auf welche Rechtsgrundlage wir uns für diese Bearbeitung stützen.
          </p>
          <ol className={css.nestedList3}>
            <li>
              <strong>
                Besuch unserer Angebote (Log Dateien)
              </strong>
              <p>
                Wenn Sie ohne weitere Angaben von Ihnen unsere Angebote verwenden, protokolliert die von uns eingesetzte Webserver-Technologie automatisch allgemeine technische Besuchsinformationen in sogenannten Log-Dateien. Dazu zählen unter anderem die IP-Adresse des verwendeten Geräts, von welchem aus der Besuch erfolgt, Angaben zum Browsertyp, zum Internet Service Provider und zum verwendeten Betriebssystem, welche Angebote bei uns aufgerufen wurden, Referenz/Exit-Seiten, der Zeitpunkt und die Dauer des Besuchs.
              </p>
              <p>
                Die Erhebung und Bearbeitung dieser Informationen erfolgt zum Zweck, die Nutzung unserer Webseiten zu ermöglichen (Verbindungsaufbau), die Sicherheit und Stabilität unserer Systeme und Angebote zu gewährleisten und zu erhöhen, die Nutzung unserer Angebote und Dienste zu analysieren, allgemeine demografische Informationen zu erheben und die Optimierung unseres Angebots zu ermöglichen, sowie zu internen statistischen Zwecken. Eine Identifikation des Benutzers findet dabei nicht statt. Ebenso wird grundsätzlich keine Verbindung zwischen diesen automatisch gesammelten Informationen und bei uns gespeicherten personenbezogenen Daten hergestellt. Die automatisch gesammelten Informationen und bei uns gespeicherten personenbezogenen Daten können jedoch verbunden werden, wenn Sie bei einem unserer Portale bereits ein registriertes Benutzerkonto haben oder als Gast bestimmte Funktionen unserer Webseiten nutzen, wie z.B. unser Kontaktformular oder unsere Anfragefunktion. Die Verbindung dieser Informationen kann erfolgen, um die Nutzung unserer Angebote und Dienste zu analysieren, allgemeine demografische Informationen zu erheben und die Optimierung unseres Internetangebots zu ermöglichen:
              </p>
              <ul style={{ listStyleType: "disc", paddingLeft: 20 }}>
                <li>
                  Wenn Sie über eine entsprechende Funktion unseres Portals einem Dritten (beispielsweise einem Inserenten) eine Nachricht senden, können diese Nachrichten inkl. Inhalt von uns gespeichert werden.
                </li>
                <li>
                  Sie können auf unserem Portal auch kostenlose Leistungen beziehen. Ihre bei der Nutzung einer kostenlosen Leistung oder mit einer Nachricht allenfalls bekanntgegebenen personenbezogenen Daten (E-Mail-Adresse, Telefonnummer etc.) können von uns beispielsweise zu Marketing- und Analysezwecken verwendet werden (siehe weiter unten in Kapitel 3.9).
                </li>
              </ul>
              <p>
                Sofern beim Besuch unserer Angebote personenbezogenen Daten von ihnen zu den oben beschriebenen Zwecken bearbeitet werden, erfolgt diese Bearbeitung in unserem berechtigten Interesse, die Nutzung unserer Webseiten zu ermöglichen (Verbindungsaufbau), die Sicherheit und Stabilität unserer Systeme und Angebote zu gewährleisten und zu erhöhen, die Nutzung unserer Angebote zu analysieren, statistisch auszuwerten, anzupassen und zu verbessern sowie die Optimierung unseres Internetangebots zu ermöglichen.
              </p>
            </li>
            <li>
              <strong>
                Registrierung eines Benutzerkontos
              </strong>
              <p>
                Für personalisierte Dienste oder zugangsgeschützte Bereiche oder zur Bearbeitung von Anfragen und Administration Ihrer Abonnemente kann Ihre Registrierung und damit die Einrichtung eines Benutzerkontos erforderlich sein. Dabei ist die Eingabe bestimmter personenbezogenen Daten erforderlich. Dies betrifft zum Beispiel
              </p>
              <ul style={{ listStyleType: "disc", paddingLeft: 20 }}>
                <li>Ihre E-Mail-Adresse, die gleichzeitig als Benutzername dienen kann</li>
                <li>Passwort sowie je nach Kontext und Angebot gegebenenfalls weitere Angaben wie</li>
                <li>Vor- Nachname</li>
                <li>Identitätsnachweise wie z.B. Kopien amtlicher Ausweise</li>
                <li>Adresse (vollständige Postadresse, PLZ, Ort)</li>
                <li>Telefonnummer</li>
                <li>Geburtsdatum</li>
                <li>Geschlecht</li>
                <li>Informationen zu abonnierten Newslettern oder sonstiger Werbung</li>
                <li>Sprachpräferenzen</li>
              </ul>
              <p>
                Über die für die Nutzung eines bestimmten Angebots als zwingend gekennzeichneten Angaben hinaus können Sie freiwillig weitere personenbezogene Daten eingeben und speichern. Mit Ihrer Bestätigung beim Abschluss der Registrierung für die Erfassung und Mutation Ihrer Angaben zum Benutzerkonto gewährleisten Sie die inhaltliche Richtigkeit der von Ihnen erfassten Angaben.
              </p>
              <p>
                Die Daten verwenden wir zur Abwicklung und Administration unserer digitalen Angebote, zur Überprüfung der eingegebenen Daten auf Plausibilität, d.h. zur Begründung, inhaltlichen Ausgestaltung, Abwicklung und Änderung der mit Ihnen abgeschlossenen Vertragsverhältnisse über Ihr Benutzerkonto sowie im Falle kostenpflichtiger Dienste zur ordnungsgemässen Rechnungsstellung. Entsprechend bearbeiten wir Ihre Daten in diesem Zusammenhang gemäss dem anwendbaren Datenschutzrecht.
              </p>
            </li>
            <li>
              <strong>
                Nutzung unserer Angebote als registrierter Nutzer
              </strong>
              <p>
                Während der Nutzung des Angebots durch die registrierten Nutzer erheben wir Daten aus statistischen Gründen, um die reibungslose Funktionsfähigkeit des Portals zu ermöglichen sowie zur Analyse und Optimierung unserer Angebote und Dienste. So sammeln wir Daten darüber, ob und wie Sie unsere digitalen Angebote nutzen, insbesondere welche Funktionen und welche Werbung Sie wahrnehmen. Es liegt in unserem berechtigten Interesse gemäss anwendbarem Datenschutzrecht unser Angebot zu optimieren, weiterzuentwickeln und zu verbessern.
              </p>
              <p>
                Sofern Sie als registrierter Benutzer unser Angebot benutzen, können statistische Daten für andere registrierte Benutzer einsehbar sein. Solche Daten werden anderen registrierten Nutzern nur in anonymisierter und/oder aggregierter Form zugänglich gemacht.
              </p>
            </li>
            <li>
              <strong>Nutzung von Mobile-Applikationen</strong>
              <p>Falls Sie mit einem Mobilgerät unsere Mobile-Applikationen benutzen, und Sie die Übertragung Ihrer GPS-Signaldaten an Dritte in den Einstellungen Ihres Mobilgerätes aktiviert haben, können wir unter Verwendung von GPS-Signaldaten Informationen über den Standort Ihres Mobilegeräts (Längen- und Breitengrad, Angaben zur horizontalen Genauigkeit) erfassen.</p>
              <p>Wir verwenden die Standortdaten dazu, Ihr Nutzererlebnis zu steigern, indem wir Ihnen über die Mobile-Applikation(en) auf Ihrem Mobilegerät standortbezogene Onlinewerbung und andere standortbezogene digitale Inhalte anzeigen (wie z.B. ortsbasierte Wetterangaben und Nachrichten). Wir fragen Sie nach Ihrer Zustimmung gemäss anwendbarem Datenschutzrecht, bevor wir Ihre Standortdaten für die genannten Anwendungen erheben.</p>
              <p>Sie haben auch nach erteilter Zustimmung jederzeit die Möglichkeit, Ihre Zustimmung zu widerrufen oder die Erhebung, Bearbeitung und Weitergabe Ihrer Standortdaten zu deaktivieren oder zu widersprechen. Wenn Sie keine standortbasierte Onlinewerbung und Inhalte erhalten möchten, können Sie den Zugriff auf Ihren Standort entweder ablehnen oder die Ortungsdienste in den Einstellungen Ihres Mobilegeräts jederzeit deaktivieren. Um die Ortungsdienste zu deaktivieren, folgen Sie bitte den Anleitungen der Gerätehersteller:</p>
              <ul style={{ paddingLeft: 20, textAlign: "left" }}>
                <li>für Apple-Geräte:<br /><a href="https://support.apple.com/de-ch/HT207092">https://support.apple.com/de-ch/HT207092</a></li>
                <li>für Android-Geräte:<br /><a href="https://support.google.com/accounts/answer/3467281?hl=de">https://support.google.com/accounts/answer/3467281?hl=de</a></li>
              </ul>
            </li>
            <li>
              <strong>
                Kauf / Bezug eines kostenpflichtigen Angebots
              </strong>
              <p>Wenn Sie ein Produkt kaufen, respektive einen kostenpflichtigen Dienst beziehen, ist die Angabe von Daten wie beispielsweise Vor- und Nachnamen, Adresse (vollständige Postadresse, PLZ, Ort) und allenfalls weiteren Daten (wie z.B. Lieferadresse, Rechnungsadresse, Zahlungsinformationen etc.) zwingend, da wir diese für die Abwicklung des Vertrages mit Ihnen gemäss anwendbarem Datenschutzrecht benötigen.</p>
              <p>Wenn Sie zum Kauf eines Produkts respektive eines kostenpflichtigen Dienstes eine Online-Zahlungsoption wie etwa Kreditkarte, TWINT oder PayPal wählen, erfolgt die Bezahlung über das Online-Zahlungssystem des jeweiligen Anbieters. Die Bearbeitung von Personen- und Zahlungsdaten erfolgt in diesem Fall direkt über den Anbieter des jeweiligen Zahlungssystems. Wir kennen und speichern Ihre Zahlungsdaten nicht. Es gelten jeweils zusätzlich die Datenschutzbestimmungen des jeweiligen Anbieters des Online-Zahlungssystems.</p>
              <p>Wir können Ihre für den Kauf oder Bezug eines kostenpflichtigen Angebots gesammelten Daten im Benutzerkonto für den nächsten Einkauf / Vertragsschluss gemäss unserer internen Archivierungs- und Datenschutzrichtlinien oder gestützt auf die gesetzlichen Anforderungen speichern. Diese nutzen wir zur Optimierung, Verbesserung und Weiterentwicklung unseres Angebots, für Marketingzwecke sowie zu statistischen Zwecken. Dafür können wir alle Informationen zu Ihren aktuellen und bisherigen Einkäufen und Vertragsschlüssen, d.h. die Produkte, die Dienstleistungen, die Anzahl Produkte und Dienstleistungen pro Einkauf sowie den Zahlungsbetrag speichern.</p>
            </li>
            <li>
              <strong>
                Kontaktaufnahme mit Kundendienst oder über Kontaktformular
              </strong>
              <p>Sofern Sie unseren Kundendienst kontaktieren, können wir Ihre Kontaktdaten (wie z.B. Anrede, Vor- und Nachnamen, Telefonnummer, E-Mail-Adresse oder Postadresse) sowie den Inhalt des Gesprächs zur Beantwortung Ihrer Anfrage bearbeiten. Dies liegt in unserem berechtigten Interesse im Sinne des anwendbaren Datenschutzrechts, Ihnen fachgerechte Auskunft zu erteilen und Ihre Anfrage korrekt zu bearbeiten.</p>
              <p>Ein- und ausgehende Beratungsgespräche mit dem Kundendienst von uns können zu Ausbildungs- und Qualitätszwecken aufgezeichnet und gespeichert werden, sofern Sie dazu eingewilligt haben.</p>
              <p>Füllen Sie ein Kontaktformular auf unseren Angeboten aus, bearbeiten wir die angegebenen personenbezogenen Daten (wie z.B. Anrede, Vor- und Nachnamen, Telefonnummer, E-Mail-Adresse oder Postadresse sowie den Inhalt der Anfrage). Auch dies liegt in unserem berechtigten Interesse, Ihnen fachgerechte Auskunft zu erteilen und Ihre Anfrage korrekt zu bearbeiten (gemäss anwendbarem Datenschutzrecht).</p>
            </li>
            <li>
              <strong>
                Teilnahme an Events, Gewinnspielen oder Wettbewerben (nachfolgend gemeinsam „Veranstaltungen“)
              </strong>
              <p>Zur freiwilligen Teilnahme an Veranstaltungen verwenden wir die von Ihnen angegebenen Daten, wie z.B. Anrede, Vor- und Nachname, E-Mail-Adresse, Postadresse und teils  eventspezifische Angaben. Diese Daten dienen der Organisation und Durchführung der Veranstaltungen sowie der Benachrichtigung und/oder Publikation der Gewinner mittels direkter Benachrichtigung oder auf sozialen Netzwerken. In diesem Zusammenhang können Ihre Daten auch gemäss Kapitel 5 weitergegeben werden, z.B. wenn wir einen Dritten mit der Durchführung oder Auswertung der Veranstaltung beauftragt haben oder die Veranstaltung von einem Dritten über unsere Angebote durchgeführt wird. </p>
              <p>Bei Events können vor Ort Fotos und Videos gemacht werden. Ein Herausschneiden von Ton- oder Bildaufnahmen wird im Nachhinein nicht vorgenommen. Aufnahmen (Foto und Video) werden ausschliesslich für eigene Zwecke verwendet (z.B. Aufschaltung auf unseren Websites, Flyer, Event Rückblick auf sozialen Medien, Newsletter, Information an die Teilnehmenden per E-Mail, etc.). </p>
            </li>
            <li>
              <strong>
                Nutzerumfragen, Interviews und Marktforschung
              </strong>
              <p>
                Wenn Sie freiwillig an einer Nutzerumfrage, einem Interview oder anderen Marktforschungsaktivitäten von uns teilnehmen, erheben wir personenbezogene Daten von Ihnen, wie z.B. Anrede, Vor- und Nachname, Kontaktangaben und weitere Informationen, die im Rahmen der Umfrage erfragt werden. Wir verwenden die von Ihnen angegebenen Daten ausschliesslich zur Verbesserung der Nutzererfahrung und zur Weiterentwicklung unserer Produkte, was ein berechtigtes Interesse von uns darstellt (gemäss anwendbarem Datenschutzrecht). Die Ergebnisse aus den Umfragen bestehen ausschliesslich aus aggregierten und anonymen Daten.
              </p>
            </li>
            <li>
              <strong>
                Marketing- und Analysezwecke
              </strong>
              <p>
                Mit Ihrer Registrierung oder der Bestellung als Gast über eines unserer Angebote können wir Ihre Daten auch für personalisierte Werbemassnahmen von uns sowie von mit uns verbunden Unternehmen, von Dritten und von unseren Aktionären verwenden. Personalisierte Werbemassnahmen umfassen insbesondere die Personalisierung von Werbung mittels digitaler Werbeanzeigen auf unseren Web-Angeboten und Mobile-Applikationen, per E-Mail, wie beispielsweise E-Mails mit allgemeinen Informationen oder werbendem Charakter (Newsletter), per Telefon, Post, Telefax, Textnachrichten, Bildnachrichten sowie auf Instant Messaging Diensten. Am Ende jedes von der uns versendeten E-Mails findet sich ein Link, über den Sie den Newsletter jederzeit abbestellen können. Den Newsletter können Sie auch jederzeit per E-Mail <a href="mailto:helpdesk@dealgo.ch">helpdesk@dealgo.ch</a> abbestellen.
              </p>
              <p>
                Wir schicken Ihnen nur Newsletter, sofern diese ähnliche Angebote betreffen oder Sie ausdrücklich zugestimmt haben. Ansonsten liegt die Auslieferung von personalisierten Inhalten und Werbung, z.B. auf unseren Portalen oder Portalen von mit uns verbundenen Unternehmen, in unserem berechtigten Interesse gemäss anwendbarem Datenschutzrecht, Ihnen unsere Produkte oder Dienste anzubieten, die Sie interessieren könnten sowie unsere Angebote optimal zu vermarkten. Sofern eine vorgängige Einwilligung, insbesondere zur Bearbeitung Ihrer Daten durch mit uns verbundene Unternehmen oder durch unsere Aktionäre notwendig ist, holen wir diese vorgängig ein.
              </p>
              <p>
                Dazu können laufend unter Einsatz verschiedener Analysetools nutzerspezifische historische und zukünftige Daten, über die wir verfügen, miteinander verknüpft und das Nutzerverhalten angebotsübergreifend analysiert, aggregiert, pseudonymisiert und anonymisiert werden. Zur Verbesserung unserer Datenbasis können wir öffentlich erhältliche Daten oder Daten von Drittanbietern hinzuziehen. Die Erkenntnisse aus Ihrer Nutzung unseres Angebotes können im Rahmen der Analyse des Nutzerverhaltens bei anderen teilnehmenden Unternehmen genutzt und verwertet werden. Eine solche Datenbearbeitung erfolgt grundsätzlich mit pseudonymisierten oder anonymisierten Daten. Sie können dieser Bearbeitung Ihrer Daten jederzeit widersprechen, in dem Sie die Voreinstellungen der Cookies anpassen.
              </p>
              <p>
                Wir betreiben auf verschiedenen Plattformen eine automatisierte Bewertung des Nutzerverhaltens, um daraus ein Profil der jeweiligen Nutzer zu erstellen, welches eine gezieltere Ansprache erlaubt. Dieses teilen wir im Falle Ihrer Einwilligung mit den zur dealgo.ch gehörenden Plattformen, um ein übergreifendes Profil zu erstellen, das die verschiedenen Plattformen für die Steuerung von Werbung, für Marketing und für Analysen nutzen können. Namentlich sind dies folgende Plattformen: Immodealgo.ch, Homegate, Immostreet.ch, home.ch, Publimmo, Acheter-Louer.ch, CASASOFT, IAZI, , Motodealgo.ch, CAR FOR YOU, anibis.ch, tutti.ch, Ricardo, Financedealgo.ch.
              </p>
              <p>Darüber hinaus bearbeiten wir Ihre Daten zu Analysezwecken, z.B. um unsere digitalen Angebote kontinuierlich zu verbessern sowie bedarfsgerechter und sicherer auszugestalten. Dies liegt in unserem berechtigten Interesse gemäss anwendbarem Datenschutzrecht, unsere Angebote auf die Bedürfnisse unserer Kunden auszurichten, marktgerecht zu verbessern und die Sicherheit unserer Angebote zu gewährleisten.</p>
              <p>
                Mit der Registrierung eines Benutzerkontos melden Sie sich grundsätzlich automatisch auch zu einem unserer Newsletter an und Ihre E-Mail-Adresse kann entsprechend auch für die Bewerbung eigener ähnlicher Angebote genutzt werden, bis Sie sich vom entsprechenden Newsletter abmelden.
              </p>
              <p>Zu den hier beschriebenen Zwecken können wir auch Auftragsdatenbearbeiter mit der technischen Abwicklung von Werbemassnahmen und der Werbung für uns selbst beauftragen, welche Ihre Daten ausschliesslich zu den oben beschriebenen Zwecken auf Weisung von uns bearbeiten (vgl. unten Kapitel 5).</p>
            </li>
            <li>
              <strong>
                Betrugsbekämpfung, Rechtsstreitigkeiten und gesetzliche Bearbeitungspflichten
              </strong>
              <p>
                Wir können Ihre Daten, die wir gemäss Kapitel 3.1 bis 3.9 erheben und bearbeiten, ausserdem zur Betrugsbekämpfung oder im Falle eines Rechtsstreites bearbeiten, was in unserem berechtigten Interesse gemäss anwendbarem Datenschutzrecht liegt, unser Geschäft zu schützen und allfällige Ansprüche von uns durchzusetzen oder geltend zu machen. Ebenfalls können wir Ihre Daten zur Erfüllung unserer gesetzlichen Pflichten im Einklang mit anwendbarem Datenschutzrecht bearbeiten.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <strong>
            Profiling
          </strong>
          <p>Wir können bestimmte Ihrer persönlichen Eigenschaften zu den in Kapitel 3 genannten Zwecken anhand Ihrer Daten automatisiert bewerten («Profiling»), wenn wir Präferenzdaten ermitteln wollen, aber auch um Missbrauchs- und Sicherheitsrisiken festzustellen, statistische Auswertungen vorzunehmen oder für betriebliche Planungszwecke. Zu denselben Zwecken können wir auch Profile erstellen, d.h. wir können Verhaltens- und Präferenzdaten, aber auch Stamm- und Vertragsdaten und Ihnen zugeordnete technische Daten kombinieren, um Sie als Person mit ihren unterschiedlichen Interessen und sonstigen Eigenschaften besser zu verstehen.</p>
          <p>In solchen Fällen achten wir auf die Verhältnismässigkeit und Zuverlässigkeit der Ergebnis-se und treffen Massnahmen gegen eine missbräuchliche Verwendung dieser Profile oder eines Profiling. Können diese Rechtswirkungen oder erhebliche Nachteile für Sie mit sich bringen, sehen wir grundsätzlich eine manuelle Überprüfung vor.</p>
        </li>
        <li>
          <strong>
            Weitergabe Ihrer Daten an Dritte
          </strong>
          <p>Wir arbeiten mit anderen Unternehmen oder Personen zusammen beziehungsweise beauftragen andere Unternehmen oder Personen mit der Bearbeitung und Speicherung von Daten gemäss dieser Datenschutzerklärung. Dies können Unternehmen der dealgo.ch, die Aktionäre der dealgo.ch inkl. deren Tochtergesellschaften, andere Dritte oder Auftragsdatenbearbeiter von uns sein. Diese Dritten können Zugang zu Ihren personenbezogenen Daten oder Nutzungsdaten erhalten, jedoch nur soweit es zur Erledigung der durch uns beauftragten Aufgaben oder zur Erfüllung der Zwecke, die in dieser Datenschutzerklärung beschrieben sind, erforderlich ist. Vorbehalten bleibt die Weitergabe Ihrer Daten aufgrund von zwingenden gesetzlichen Pflichten.</p>
          <p>
            Sofern im Einzelfall nicht ein besonderes Berufsgeheimnis (z.B. Bankgeheimnis) anwendbar ist, unterstehen wir keiner beruflichen Schweigepflicht. Teilen Sie uns bitte im Einzelfall mit, sollten Sie der Ansicht sein, dass bestimmte Personendaten einer Schweigepflicht unterstehen, damit wir Ihr Anliegen prüfen können.
          </p>
          <ol className={css.nestedList5}>
            <li>
              <strong>
                Weitergabe an Unternehmen der dealgo.ch
              </strong>
              <p>Sofern mit den oben in Kapitel 3 beschriebenen Zwecken vereinbar oder rechtlich zulässig, können Ihre Daten sowie Nutzungsdaten zu Ihrer Person resp. Ihrem Benutzerkonto, an Unternehmen der dealgo.ch zur Auswertung, Verbesserung und bedarfsgerechten Ausgestaltung unserer Angebote, zur Kundenpflege, zur Personalisierung und zu Marketingzwecken genutzt und weitergegeben werden.</p>
              <p>Sofern hierzu Ihre Einwilligung notwendig ist, holen wir diese vorgängig bei Ihnen ein. Eine solche Einwilligung kann jederzeit widerrufen werden. Wenn keine Einwilligung notwendig ist, beruht die Weitergabe Ihrer Daten auf unserem berechtigten Interesse oder dem berechtigten Interessen eines Unternehmens der dealgo.ch oder mit der dealgo.ch verbundene Unternehmen und Joint Ventures der dealgo.ch, gemäss anwendbarem Datenschutzrecht die Nutzung unserer Webseiten zu ermöglichen (Verbindungsaufbau), die Sicherheit und Stabilität unserer Systeme und Angebote zu gewährleisten und zu erhöhen, die Nutzung unserer Angebote zu analysieren, statistisch auszuwerten, anzupassen und zu verbessern, Ihnen Werbung anzuzeigen oder zu schicken sowie die Optimierung unseres Internetangebots zu ermöglichen.</p>
              <p>Zudem betreiben wir auf verschiedenen Plattformen eine automatisierte Bewertung des Nutzerverhaltens, um daraus ein Profil der jeweiligen Nutzer zu erstellen (Kapitel 3.9). Dieses teilen wir im Falle Ihrer Einwilligung mit den zur dealgo.ch gehörenden Plattformen, um ein übergreifendes Profil zu erstellen, das die verschiedenen Plattformen für die Steuerung von Werbung, für Marketing und für Analysen nutzen können. Namentlich sind dies folgende Plattformen: Immodealgo.ch, Homegate, Immostreet.ch, home.ch, Publimmo, Acheter-Louer.ch, CASASOFT, IAZI, , Motodealgo.ch, CAR FOR YOU, anibis.ch, tutti.ch, Ricardo, Financedealgo.ch.</p>
            </li>
            <li>
              <strong>
                Weitergabe an Aktionäre der dealgo.ch
              </strong>
              <p>Wir können Ihre Daten an die Aktionäre der dealgo.ch gemäss vorliegendem Kapitel 5 weitergeben. Die Aktionäre dürfen ihrerseits die Daten ihren gehaltenen Tochtergesellschaften weitergeben, sofern sie Ihre Daten nur zu den gleichen Zwecken bearbeiten (nachfolgend beschrieben), wie dies die Aktionäre selber tun dürfen.</p>
              <p>Eine Weitergabe erfolgt zur Auswertung, Verbesserung und bedarfsgerechten Ausgestaltung der Produkte und Dienste unserer Aktionäre, zur Personalisierung, zu Marketingzwecken sowie zur Betrugs- und Missbrauchsbekämpfung. Eine Weitergabe der Daten wie hierin beschrieben erfolgt nur, sofern dies rechtlich zulässig ist oder wir Ihre Einwilligung vorab bei Ihnen eingeholt haben. Eine solche Einwilligung kann jederzeit widerrufen werden.</p>
            </li>
            <li>
              <strong>Weitergabe an Dienstleister</strong>
              <p>
                Darüber hinaus geben wir Ihre Daten an Dritte weiter, soweit dies zur Vertragsabwicklung gemäss anwendbarem Datenschutzrecht erforderlich ist. Für diesen Zweck geben wir die erforderlichen Daten allenfalls an Transportunternehmen, Banken und andere Dienstleister, wie z.B. Hard- und Softwareanbieter, weiter. Diese Dienstleister nutzen Ihre Daten ausschliesslich für die Auftragsabwicklung und nicht für weitere Zwecke. Soweit dies für die in Satz 1 genannten Zwecke erforderlich ist, kann die Weitergabe auch ins Ausland erfolgen, etwa um die Zustellung von Waren zu ermöglichen. Mehr Informationen betreffend Übermittlungen ins Ausland entnehmen Sie Kapitel 7.
              </p>
            </li>
            <li>
              <strong>
                Weitergabe an weitere Dritte
              </strong>
              <p>Wenn Sie auf integrierte Angebote Dritter klicken oder integrierte Kontaktformulare von Dritten verwenden, können wir Ihre Daten gemäss Angebot oder Kontaktformular weitergeben. Zudem können wir Ihre Daten an Dritte weitergeben, wenn diese auf unseren Angeboten personalisierte Werbung oder Anzeigen schalten wollen oder Ihnen ein persönliches Angebot unterbreiten möchten. Darüber hinaus können Ihre Daten an Dritte weitergegeben werden, wenn wir gemeinsam mit einem Dritten ein Angebot anbieten.</p>
              <p>Zur Betrugs- und Missbrauchsbekämpfung oder bei sonstigem Verdacht auf strafbare Handlungen können wir Ihre Daten an in- und ausländische Strafverfolgungsbehörden und Gerichte offenlegen. Bei Bedarf übertragen wir Forderungen an andere Unternehmen wie z.B. Inkassounternehmen.</p>
              <p>Abgesehen von der vorstehend beschriebenen Weitergabe Ihrer Daten geben wir Ihre Daten nur weiter, wenn Sie gemäss anwendbarem Datenschutzrecht entweder ausdrücklich eingewilligt haben, hierfür eine gesetzliche Verpflichtung besteht oder dies zur Durchsetzung unserer Rechte, insbesondere zur Durchsetzung von Ansprüchen aus dem Vertragsverhältnis oder zur Betrugsbekämpfung, welche ein überwiegendes Interesse von uns darstellen, erforderlich ist.</p>
              <p>Bei einem Verkauf, einer Fusion oder sonstigen Reorganisation einiger oder aller Vermögenswerte unseres Unternehmens können personenbezogene Daten als Teil dieser Transaktion oder Reorganisation übertragen, verkauft oder anderweitig mit Dritten geteilt werden.</p>
              <p>Sofern wir in Vorleistung treten, beispielsweise bei einem Kauf auf Rechnung, holen wir zur Wahrung unserer berechtigten Interessen am Vertragsschluss mit zahlungsfähigen Kunden (gemäss anwendbarem Datenschutzrecht) gegebenenfalls eine Bonitätsauskunft auf der Basis mathematisch-statistischer Verfahren bei einer Auskunftei ein. Hierzu übermitteln wir die zu einer Bonitätsprüfung benötigten personenbezogenen Daten an eine Auskunftei und verwenden die erhaltenen Informationen über die statistische Wahrscheinlichkeit eines Zahlungsausfalles für eine Entscheidung über die Begründung, Durchführung oder Beendigung des Vertragsverhältnisses. Die Bonitätsauskunft kann Wahrscheinlichkeitswerte (Score-Werte) beinhalten, die auf Basis wissenschaftlich anerkannter mathematisch-statistischer Verfahren berechnet wurden und in deren Berechnung unter anderem Kontaktdaten einfliessen. Ihre schutzwürdigen Interessen werden gemäss den gesetzlichen Bestimmungen berücksichtigt.</p>
              <p>Eine sonstige Weitergabe, Übermittlung oder ein Verkauf Ihrer personenbezogenen Daten an Dritte ausserhalb von der dealgo.ch sowie unserer Aktionäre erfolgt nicht, es sei denn, dass dies zur Abwicklung eines Vertrages, welchen Sie mit uns abgeschlossen haben, erforderlich ist oder Sie ausdrücklich eingewilligt haben.</p>
            </li>
          </ol>
        </li>
        <li>
          <strong>
            Verwendung von Cookies, Tracking- und Analysetools sowie Plug-Ins und andere Einbindungen von Angeboten Dritter
          </strong>
          <ol className={css.nestedList6}>
            <li>
              <strong>Cookies</strong>
              <p>Cookies helfen, Ihren Besuch auf unseren Webseiten einfacher, angenehmer und sinnvoller zu gestalten, sowie das Funktionieren unserer Angebote und Portale sicherzustellen und dienen dazu, Ihnen interessenbasierte Werbung bereitstellen. Cookies sind Informationsdateien, die Ihr Webbrowser automatisch auf der Festplatte Ihres Computers speichert, wenn Sie unsere Internetseite besuchen. Unsere digitalen Angebote lassen sich grundsätzlich auch ohne die Annahme von Cookies nutzen, allerdings können dann einzelne Funktionalitäten eingeschränkt sein.</p>
              <p>Bei der Nutzung unserer Website können, die von uns eingesetzten Cookies Nutzungsdaten wie die IP-Adresse Ihres Gerätes, besuchte Seiten, verwendeter Browser, Datum, Uhrzeit usw. sammeln und speichern. Diese Daten lassen grundsätzlich keine Rückschlüsse auf Ihre Person zu, ausser Sie sind in Ihrem Benutzerkonto eingeloggt.</p>
              <p>Cookies werden beim Schliessen des Browsers in einer Textdatei auf dem Computer abgelegt und beim nächsten Aufruf des Webservers wieder aufgerufen. Cookies beschädigen weder die Festplatte Ihres Rechners noch werden von Cookies persönliche Daten der Anwender an uns übermittelt.</p>
              <p>Wir setzen Cookies beispielsweise ein, um Sie nach einem ersten Besuch unserer Webseiten, Angebote oder Portale wiederzuerkennen, dabei werden grundsätzlich nicht Sie als Nutzer erkannt, sondern lediglich der benutzte Computer oder das Mobilgerät bzw. der genutzten Browser. Darüber hinaus setzen wir sie ein, um nachzuvollziehen, wer unsere Angebote und Portale besucht hat und daraus abzuleiten, wie häufig bestimmte Seiten oder Angebote besucht werden, welche Teile des Portals sich besonderer Beliebtheit erfreuen und um ganz allgemein zu erheben, wie das Portal verwendet wird. Andere Cookies verwenden wir, damit Sie sich auf unserem Portal frei bewegen und deren Funktionalitäten nutzen können, so etwa auch beim Zugriff auf zugangsgeschützte Bereiche, zur effizienten Navigation zwischen verschiedenen Seiten und zur Speicherung von Präferenzen. Solche Cookies können etwa auch erforderlich sein, um Warenkörbe oder Zahlungsfunktionen zu nutzen. Unter Einsatz von Cookies lassen sich auch von Ihnen gewählte Optionen oder getroffene Entscheidungen als Einstellungen verwenden, um Ihren Besuch auf dem Portal bequemer zu gestalten. Allgemein dient der Einsatz von Cookies dazu, unsere Dienste zu verbessern, effektiver und sicherer zu machen. Mittels Cookies werden aber auch Informationen gesammelt, um Ihnen Werbung anzubieten, die Sie interessieren könnte. Schliesslich helfen unsere Cookies, online Werbeanzeigen stärker auf Ihre möglichen Interessen abzustimmen und vermindern dadurch das Einblenden von Werbung, die nicht oder kaum von Interesse für Sie sein könnten.</p>
              <p>Die meisten Internet-Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch jederzeit so konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, wenn Sie ein neues Cookie erhalten (besuchen Sie dafür die entsprechenden Einstellungen in Ihrem Browser). Sie können auch Cookies auf Ihrem Computer oder mobilen Endgerät löschen, indem Sie die entsprechende Funktion Ihres Browsers benutzen.</p>
              <p>Sie können jederzeit entscheiden, ob Sie von gezielter und auf Ihre möglichen Interessen abgestimmte Werbung profitieren möchten oder nicht. Eine Ablehnung bedeutet jedoch nicht, dass Sie keine Online Werbung mehr eingeblendet erhalten. Es bedeutet lediglich, dass die Werbung, die Sie auf den Webseiten angezeigt bekommen, nicht auf Ihre vermuteten Interessen zugeschnitten sind und daher weniger relevant für Sie sein könnte.</p>
              <p>Die meisten von uns verwendeten Cookies sind temporäre Session Cookies, die nach Ende der Browser-Sitzung automatisch wieder vom Computer oder mobilen Endgerät gelöscht werden. Darüber hinaus verwenden wir aber auch permanente Cookies. Diese bleiben nach dem Ende der Browser-Sitzung auf dem Computer oder mobilen Endgerät gespeichert. Diese permanenten Cookies bleiben je nach Art des Cookies zwischen einem Monat und zehn Jahren auf dem Computer oder mobilen Endgerät gespeichert und werden erst nach Ablauf der programmierten Zeit automatisch deaktiviert.
                Weitere Details zu den von uns verwendeten Cookies und Tracking-Tools entnehmen Sie bitte den Cookie-Einstellungen.</p>
            </li>
            <li>
              <strong>
                Tracking- und Analyse-Tools
              </strong>
              <p>Die Nutzung unserer digitalen Angebote wird überdies mittels verschiedener technischer Systeme, überwiegend von Drittanbietern wie beispielsweise Google Analytics, gemessen und ausgewertet. Diese Messungen können sowohl anonym als auch personenbezogen erfolgen. Dabei ist es möglich, dass die erhobenen Daten von uns oder den Drittanbietern solcher technischer Systeme ihrerseits zur Bearbeitung an Dritte weitergegeben werden.</p>
              <p>Wir verwenden beispielsweise Google Analytics, eine Dienstleistung von Google Inc. Damit können die erfassten Daten grundsätzlich an einen Server von Google in den USA übermittelt werden, wobei die IP-Adressen mittels IP-Anonymisierung anonymisiert werden, so dass eine Zuordnung nicht möglich ist. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Der Erfassung und Bearbeitung dieser Daten mittels Google Analytics kann widersprochen werden, indem Sie ein Opt-Out-Cookie setzen, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert: <a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>.</p>
              <p>Weitere Details zu den von uns verwendeten Cookies und Tracking-Tools entnehmen Sie bitte den Cookie-Einstellungen.</p>
            </li>
            <li>
              <strong>
                Plug-Ins und andere Einbindungen von Angeboten Dritter
              </strong>
              <p>Unsere digitalen Angebote sind auf vielfältige Weise mit Funktionen und Systemen Dritter vernetzt, so etwa durch Einbindung von Plug-Ins sozialer Netzwerke Dritter wie insbesondere Facebook, LinkedIn, YouTube oder Twitter.</p>
              <p>Wenn Sie bei diesen Dritten ein Benutzerkonto haben, ist es diesen Dritten unter Umständen ebenfalls möglich, Ihre Nutzung unserer digitalen Angebote zu messen und auszuwerten. Dabei können weitere personenbezogene Daten, wie IP-Adresse, persönliche Browsereinstellungen und andere Parameter an diese Dritten übermittelt und dort gespeichert werden. Bei der Verwendung eines Social Login-Services wie beispielsweise Facebook-Connect, kann uns der Anbieter personenbezogene Daten wie beispielsweise Name, E-Mail-Adresse und Profilbild, die bei ihm über Sie hinterlegt sind, übermitteln. Über die Nutzung solcherart durch Dritte erhobener personenbezogener Daten haben wir keine Kontrolle und übernehmen keine Verantwortung oder Haftung. Wir verweisen auf die oben verlinkten, detaillierten weiteren diesbezüglichen Informationen. Informationen zur Erhebung und Nutzung der Daten durch Drittanbieter finden Sie in den Datenschutzhinweisen der entsprechenden Drittanbieter.</p>
            </li>
          </ol>
        </li>
        <li>
          <strong>
            Übermittlung personenbezogener Daten ins Ausland
          </strong>
          <p>
            Wir können Ihre personenbezogenen Daten auch an dritte Unternehmen oder beauftragte Dienstleister im Ausland, insbesondere in Europa, Asien und den USA, übertragen, sofern dies für die in dieser Datenschutzerklärung beschriebenen Datenbearbeitungen zweckmässig ist. Diese sind im gleichen Umfang wie wir selbst zum Datenschutz verpflichtet. Wenn das Datenschutzniveau in einem Land nicht dem schweizerischen entspricht, stellen wir in angemessener Weise sicher, dass der Schutz Ihrer personenbezogenen Daten demjenigen in der Schweiz jederzeit entspricht. Dies stellen wir z.B. durch das Abschliessen von EU Model Clauses oder anderen durch den Eidgenössischen Datenschutz- und Öffentlichkeitsbeauftragten genehmigten Verträge mit den beauftragten Dienstleistern sicher. In Ausnahmefällen kann die Übermittlung in Länder ohne angemessenen Schutz auch in anderen Fällen zulässig sein, z.B. gestützt auf eine Einwilligung, im Zusammenhang mit einem Rechtsverfahren im Ausland oder wenn die Übermittlung für die Abwicklung eines Vertrags erforderlich ist.
          </p>
        </li>
        <li>
          <strong>
            Wie lange speichern wir Ihre Daten?
          </strong>
          <p>Wir bewahren Ihre Daten nur so lange auf, wie es rechtlich oder entsprechend dem Bearbeitungszweck notwendig ist, insbesondere zur Erfüllung des Auftrags oder Vertrags sowie zur Einhaltung gesetzlicher Pflichten.</p>
          <p>Bei Analysen Ihrer Daten speichern wir Ihre Daten so lange, bis die Analyse abgeschlossen ist oder Sie der weiteren Bearbeitung widersprochen haben. Speichern wir Daten aufgrund einer Vertragsbeziehung mit Ihnen, bleiben diese Daten mindestens so lange gespeichert wie die Vertragsbeziehung besteht und längstens so lange Verjährungsfristen für mögliche Ansprüche von uns laufen oder gesetzliche oder vertragliche Aufbewahrungspflichten bestehen. Generell speichern wir Personendaten zudem, solange wir ein berechtigtes Interesse an der Speicherung haben. Das kann insbesondere dann der Fall sein, wenn wir Personendaten benötigen, um Ansprüche durchzusetzen oder abzuwehren, zu Archivierungszwecken und zur Gewährleistung der IT-Sicherheit.</p>
          <p>Die Inhalte des Kontaktformulars und die folgende E-Mail-Korrespondenz müssen von uns während mindestens sechs Monaten von Gesetzes wegen aufbewahrt werden.</p>
          <p>Anschliessend streben wir die Anonymisierung der Daten an, um sie für statistische Zwecke weiter verwenden zu können. Ist dies aus irgendeinem Grunde nicht möglich, erfolgt die Löschung, sofern wir Ihre Daten nicht mehr für die angegebenen Bearbeitungszwecke benötigen und auch gesetzlich nicht mehr verpflichtet sind, sie aufzubewahren.</p>
        </li>
        <li>
          <strong>
            Wie schützen wir Ihre Daten?
          </strong>
          <p>Wir treffen geeignete technische und organisatorische Vorkehrungen, um Ihre Daten gewissenhaft vor Verlust, Zerstörung, Verfälschung, Manipulation oder unberechtigtem Zugriff zu schützen. Wir betreiben sichere Datennetze, die den jeweils geltenden technischen Standards entsprechen. Unsere Sicherheitsmassnahmen werden entsprechend der technologischen Entwicklung fortlaufend angepasst und verbessert.</p>
          <p>Wenn Sie sich bei uns als Benutzer registrieren, ist der Zugang zu Ihrem Benutzerkonto jeweils nur nach Eingabe Ihres persönlichen Passwortes möglich. Zahlungs- und Zugangsinformationen sollten Sie stets vertraulich behandeln und das Browserfenster schliessen, wenn Sie die Kommunikation mit uns beendet haben, insbesondere wenn Sie den Computer gemeinsam mit anderen nutzen.</p>
          <p>Unsere Mitarbeiter und die von uns beauftragten Dienstleistungsunternehmen sind von uns zur Verschwiegenheit und zur Einhaltung der datenschutzrechtlichen Bestimmungen verpflichtet worden.</p>
        </li>
        <li>
          <strong>
            Welche Rechte haben Sie in Bezug auf Ihre Daten?
          </strong>
          <p>Gemäss anwendbarem Datenschutzrecht haben Sie folgende Rechte: </p>
          <p>Auskunftsrecht:<br />Sie haben das Recht, jederzeit zu erfahren, ob und welche personenbezogenen Daten wir von Ihnen bearbeiten.</p>
          <p>Recht auf Berichtigung Ihrer Daten:<br />Sie sind jederzeit dazu berechtigt, Ihre personenbezogenen Daten berichtigen zu lassen, sollten Sie merken, dass wir falsche Daten von Ihnen bearbeiten.</p>
          <p>Recht auf Löschung Ihrer Daten:<br />Sollte die Bearbeitung Ihrer Daten nicht mehr notwendig sein, weil Sie beispielsweise kein Vertragsverhältnis mehr mit uns haben oder mit der Bearbeitung Ihrer Daten nicht mehr einverstanden sind, dürfen Sie die Löschung Ihrer personenbezogenen Daten verlangen. Wir werden Ihre personenbezogenen Daten löschen, vorausgesetzt, dass wir keine andere Verpflichtung (z.B. gesetzliche Aufbewahrungspflicht) oder kein überwiegendes Interesse (z.B. im Zusammenhang mit einem gerichtlichen Verfahren oder Betrugsbekämpfung) daran haben, Ihre personenbezogenen Daten für eine gewisse Zeit weiter aufzubewahren oder zu bearbeiten.</p>
          <p>Eine Löschung Ihrer personenbezogenen Daten kann bewirken, dass Sie die von Ihnen registrierten Dienste nicht mehr weiter beziehen oder nutzen können.</p>
          <p>Recht auf Einschränkung oder Sperrung der Bearbeitung Ihrer Daten:<br />Sie haben jederzeit das Recht, die Bearbeitung Ihrer Daten einschränken oder sperren zu lassen, vorausgesetzt, dass wir keine andere Verpflichtung haben, Ihre Daten für eine gewisse Zeit aufzubewahren und zu bearbeiten.</p>
          <p>Recht auf Herausgabe Ihrer Daten:<br />Unter gewissen Voraussetzungen haben Sie Anspruch, dass wir Ihnen oder einem von Ihnen bestimmten Dritten Ihre personenbezogenen Daten in einem gängigen Format weitergeben oder Ihnen Ihre Daten in einem gängigen Format herausgeben.</p>
          <p>Widerspruchsrecht:<br />Sie können der Bearbeitung Ihrer Daten zu Marketing- und Analysezwecken sowie der Weitergabe Ihrer Daten innerhalb der dealgo.ch gemäss Kapitel 3.9 jederzeit per E-Mail an <a href="mailto:helpdesk@dealgo.ch">helpdesk@dealgo.ch</a> widersprechen.</p>
          <p>Ein solcher Widerspruch schliesst die Erhebung von personenbezogenen Daten nicht gänzlich aus, sondern nur für Marketing- und Analysezwecke sowie für die Weitergabe Ihrer Daten innerhalb der dealgo.ch gemäss Kapitel 3.9.</p>
          <p>Darüber hinaus können Sie jeglicher Datenbearbeitung, die auf einem berechtigten Interesse von uns beruht, per E-Mail an <a href="mailto:helpdesk@dealgo.ch">helpdesk@dealgo.ch</a> widersprechen, wenn Sie Gründe, die sich aus ihrer besonderen Situation ergeben, darlegen können. </p>
          <p>Widerruf Ihrer Einwilligung:<br />Sie haben stets das Recht eine einmal erteilte Einwilligung jederzeit zu widerrufen. Wenn wir die Mitteilung erhalten haben, dass Sie Ihre Einwilligung zurückziehen, werden wir Ihre persönlichen Daten nicht mehr für diese bestimmten Zwecke bearbeiten, sofern nicht ein anderes berechtigtes Interessen daran besteht. Der Widerruf Ihrer Einwilligung hat keine Auswirkung auf Datenbearbeitungen, die vor Widerruf vorgenommen wurden.</p>
          <p>Beschwerde an eine Aufsichtsbehörde:<br />Überdies haben Sie das Recht bei der zuständigen Aufsichtsbehörde, in der Schweiz ist das der Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte, Beschwerde betreffend die Datenbearbeitung zu erheben. Dies können Sie bei der Aufsichtsbehörde an Ihrem Wohnort, Arbeitsplatz oder dem Ort der angeblichen Datenschutzverletzung vornehmen.</p>
          <p>Sie können Ihre Rechte jederzeit mit schriftlicher Mitteilung und entsprechendem Nachweis Ihrer Identität an untenstehender Adresse ausüben.</p>
          <p>Verwenden Sie dafür bitte den E-Mail-Account, mit dem Sie sich bei uns registriert haben. So können wir Ihre Identität überprüfen.</p>
          <p>Darüber hinaus behalten wir uns vor, Ihre Identität mittels Kopie des Passes, Kopie der Vor- und Rückseite der ID-Karte oder Kopie des Schweizerischen Führerausweises oder auf andere Art und Weise zu überprüfen. Im Zusammenhang mit Ihrer Kontaktaufnahme zur Ausübung Ihrer Rechte können wir mit Ihnen korrespondieren.</p>
          <p>Bitte beachten Sie, dass die Gewährung Ihrer Rechte teilweise aus gesetzlichen Gründen oder gestützt auf das Datenschutzrecht verweigert oder eingeschränkt werden können. Wir werden Sie, wenn rechtlich erforderlich bzw. erlaubt, über die Gründe für unseren Entscheid informieren.</p>
        </li>
        <li>
          <strong>
            Wie können Sie uns kontaktieren?
          </strong>
          <p>Wenn Sie Fragen zur Bearbeitung Ihrer Daten haben, Auskünfte verlangen möchten oder die Löschung Ihrer Daten beantragen möchten, wenden Sie sich bitte an unseren Ansprechpartner für Datenschutz, indem Sie eine E-Mail an <a href="mailto:helpdesk@dealgo.ch">helpdesk@dealgo.ch</a> schicken.</p>
          <p>Die Kontaktdaten unseres Datenschutzbeauftragten lauten wie folgt:</p>
          <p>dealgo.ch by Eliekhudari<br />
            Data Protection Officer<br />
            winterthurerstrasse<br />
            8006 Zürich </p>
        </li>
        <li>
          <strong>
            Änderungen zu dieser Datenschutzerklärung
          </strong>
          <p>Es gilt jeweils die aktuelle, auf unserer Website veröffentlichte Datenschutzerklärung.</p>
          <p>Wir passen diese Datenschutzerklärung jeweils dem aktuellsten Stand der Massnahmen rund um die Verwendung und den Schutz von personenbezogenen Daten an. Bitte lesen Sie diese Datenschutzerklärung in regelmässigen Abständen wieder, sodass Sie immer auf dem neuesten Stand sind, was wir mit Ihren personenbezogenen Daten machen und wie Sie Ihre Rechte wahrnehmen können.</p>
        </li>
      </ol>
      <p>Stand: 1.10. 2023</p>
    </div >
  );
};

// Presentational component for PrivacyPolicyPage
const PrivacyPolicyPageComponent = props => {

  const intl = useIntl();
  const title = intl.formatMessage({ id: 'PrivacyPolicyPage.title' });

  return (
    <Page className={css.root} title={title}>
      <LayoutSingleColumn
        topbar={<TopbarContainer />}
        footer={<FooterContainer />}
      >
        <div className={css.content}>
          <PrivacyPolicyContent />
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

PrivacyPolicyPageComponent.propTypes = {};

const mapStateToProps = state => {
  return {};
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const PrivacyPolicyPage = compose(connect(mapStateToProps))(PrivacyPolicyPageComponent);

export { PrivacyPolicyPageComponent, PrivacyPolicyContent };
export default PrivacyPolicyPage;
